// Mixins
import uiMixin from '@/mixins/uiMixin'
// Vuex
import { mapActions } from 'vuex'
// Services
import { createCustomToken, verifyIdToken } from '@/services/authentication'
import { signInWithCustomToken } from '@/firebase/authentication'
// Utils
import { get, isNil } from 'lodash'
import { getSessionCookie } from '@/utils/session-cookie'

export default {
  name: 'LoginPartner',
  mixins: [uiMixin],
  data() {
    return {
      requestMessage: 'Accediendo al panel...',
      processingRequest: true,
      requestResult: true
    }
  },
  async mounted() {
    await this.getEveryNeededData()
  },
  methods: {
    ...mapActions('authentication', ['loginPartner', 'logout']),
    /**
     * Obtenemos/establecemos todos los datos
     * necesarios para el componente
     */
    async getEveryNeededData() {
      try {
        // Loading
        this.processingRequest = true

        // Obtenemos la compañía que deseamos logar de la URL
        const companyId = get(this.$route, 'query.companyId', null)

        // Vaciamos los posibles datos que tengamos en store
        // de anteriores accesos
        this.logout()

        // Usamos la cookie almacenada para logar
        const cookie = getSessionCookie()

        if (isNil(cookie)) {
          throw new Error('No se ha encontrado ninguna cookie de sesión.')
        }

        // Verificamos token en Firebase (obtenemos datos del usuario en Firebase)
        const userToken = await verifyIdToken(cookie)

        if (isNil(userToken)) {
          throw new Error('Token del usuario no válido.')
        }

        // Generamos un token que nos servirá para logarnos en Firebase
        const customToken = await createCustomToken(userToken.uid)

        if (isNil(customToken)) {
          throw new Error('No se pudo generar un token.')
        }

        // Finalmente logamos en el sistema con el token recibido
        const userPartner = await signInWithCustomToken(customToken)

        // Procedemos a lanzar el proceso de logado del partner
        await this.loginPartner({
          companyId,
          userPartnerId: userPartner.user.uid
        })
      } catch (error) {
        // show error
        this.requestMessage = error.message
        this.requestResult = false
      } finally {
        // Loading
        this.processingRequest = false
      }
    }
  }
}
